import March from '../img/March.jpg'

import April from '../img/April.jpg'

import May from '../img/May.jpg'

import June from '../img/June.jpg'

import July from '../img/July.jpg'

import August from '../img/August.jpg'

import September from '../img/September.png'

import October from '../img/October.png'

import November from '../img/November.png'

import December from '../img/December.png'

import January from '../img/January.png'

import Febuary from '../img/Febuary.png'

import March25 from '../img/March25.png'

import Mix from '../img/mix.jpg'

import Mix1SC from '../img/mix1SC.jpg'
import Mix1YT from '../img/mix1YT.jpg'

import Mix2SC from '../img/mix2SC.jpg'
import Mix2YT from '../img/mix2YT.jpg'

import Mix3SC from '../img/mix3SC.jpg'
import Mix3YT from '../img/mix3YT.jpg'

import MixHouseSC from '../img/mixHouseSC.jpg'
import MixHouseYT from '../img/mixHouseYT.jpg'

import Mix4 from '../img/mix4.png'

import Mix5 from '../img/mix5.jpg'

import Mix6 from '../img/mix6.jpg'

import MixNYE from '../img/MixNYE.jpg'

import Mix7 from '../img/mix7.jpg'

const playlistData = {
  playlist: [
    {
      name: "Randomix #4",
      date: new Date("2025-02-09"),
      nameParams: "randomix-4",
      cover: Mix7,
      type:"mix",
      data: [
        {
          link: 'https://soundcloud.com/etidou/randomix-4',
          picture: Mix7,
          plateform: "soundcloud",
          name: "Mix",
          other: true
        },
        {
          link: 'https://youtu.be/zKiQf_alOJw',
          picture: Mix7,
          plateform: "youtube",
          name: "Mix & Visualizer",
          other: true
        },
      ],
    },
    {
      name: "Randomix NYE",
      date: new Date("2025-01-01"),
      nameParams: "randomix-nye",
      cover: MixNYE,
      type:"mix",
      data: [
        {
          link: 'https://soundcloud.com/etidou/randomix-nye',
          picture: MixNYE,
          plateform: "soundcloud",
          name: "Mix",
          other: true
        },
        {
          link: 'https://youtu.be/DIUjbbqh154',
          picture: MixNYE,
          plateform: "youtube",
          name: "Mix & Visualizer",
          other: true
        },
      ],
    },
    {
      name: "Randomix #3",
      date: new Date("2024-11-11"),
      nameParams: "randomix-3",
      cover: Mix6,
      type:"mix",
      data: [
        {
          link: 'https://soundcloud.com/etidou/randomix-3',
          picture: Mix6,
          plateform: "soundcloud",
          name: "Mix",
          other: true
        },
        {
          link: 'https://youtu.be/ibV7Q8Yiyvo',
          picture: Mix6,
          plateform: "youtube",
          name: "Mix & Visualizer",
          other: true
        },
      ],
    },
    {
      name: "Randomix #2",
      date: new Date("2024-10-27"),
      nameParams: "randomix-2",
      cover: Mix5,
      type:"mix",
      data: [
        {
          link: 'https://soundcloud.com/etidou/randomix-2',
          picture: Mix5,
          plateform: "soundcloud",
          name: "Mix",
          other: true
        },
        {
          link: 'https://youtu.be/_y4SxXm3cWA',
          picture: Mix5,
          plateform: "youtube",
          name: "Mix & Visualizer",
          other: true
        },
      ],
    },
    {
      name: "Randomix #1",
      date: new Date("2024-09-29"),
      nameParams: "randomix-1",
      cover: Mix4,
      type:"mix",
      data: [
        {
          link: 'https://soundcloud.com/etidou/randomix-1',
          picture: Mix4,
          plateform: "soundcloud",
          name: "Mix",
          other: true
        },
        {
          link: 'https://youtu.be/_MUXgMpCfhM',
          picture: Mix4,
          plateform: "youtube",
          name: "Mix & Visualizer",
          other: true
        },
      ],
    },
    {
      name: "Chipie Club - House Rap",
      date: new Date("2024-09-26"),
      nameParams: "chipie-club-2",
      cover: MixHouseYT,
      type:"mix",
      data: [
        {
          link: 'https://soundcloud.com/etidou/chipie-club-mix-house-rap',
          picture: MixHouseSC,
          plateform: "soundcloud",
          name: "Mix",
          other: true
        },
        {
          link: 'https://youtu.be/h_nD4ZemYu8',
          picture: MixHouseYT,
          plateform: "youtube",
          name: "Mix",
          other: true
        },
      ],
    },
    {
      name: "Chipie Club - 21/08/2024",
      date: new Date("2024-08-30"),
      nameParams: "chipie-club-1",
      cover: Mix3SC,
      type:"mix",
      data: [
        {
          link: 'https://on.soundcloud.com/1Ycp5Ap1tzCkjCpd8',
          picture: Mix3SC,
          plateform: "soundcloud",
          name: "Mix only",
          other: true
        },
        {
          link: 'https://youtu.be/G5JgUa-QKC8',
          picture: Mix3YT,
          plateform: "youtube",
          name: "VJ & Mix",
          other: true
        },
      ],
    },
    {
      name: "VJing & Mixing Session #2",
      date: new Date("2024-08-18"),
      nameParams: "vjing-mixing-session-2",
      cover: Mix2YT,
      type:"mix",
      data: [
        {
          link: 'https://soundcloud.com/etidou/mix-2',
          picture: Mix2SC,
          plateform: "soundcloud",
          name: "Mix only",
          other: true
        },
        {
          link: 'https://youtu.be/aNAXXapUkuc',
          picture: Mix2YT,
          plateform: "youtube",
          name: "VJ & Mix",
          other: true
        },
      ],
    },
    {
      name: "VJing & Mixing Session #1",
      date: new Date("2024-07-14"),
      nameParams: "vjing-mixing-session-1",
      cover: Mix1YT,
      data: [
        {
          link: 'https://soundcloud.com/etidou/mix-1',
          picture: Mix1SC,
          plateform: "soundcloud",
          name: "Mix only",
          other: true
        },
        {
          link: 'https://www.youtube.com/watch?v=-FXAzZUjHVE',
          picture: Mix1YT,
          plateform: "youtube",
          name: "VJ & Mix",
          other: true
        },
      ],
    },
    {
      name: "Lille VJ Fest 2024",
      nameParams: "live-vj-set",
      cover: Mix,
      type:"mix",
      date: new Date("2024-05-11"),
      data: [
        {
          link: 'https://soundcloud.com/etidou/sets/lille-vj-fest-2024-mix',
          picture: Mix,
          plateform: "soundcloud",
          name: "Lille VJ Fest 2024 Playlist",
          other: true
        },
        {
          link: 'https://youtu.be/LheXuMiS1hk',
          picture: Mix,
          plateform: "youtube",
          name: "Lille VJ Fest 2024 Mix",
          other: true
        },
      ],
    },

    {
      name: "Decouverte Mars 25'",
      nameParams: "march-25",
      date: new Date("2025-04-01"),
      type:"playlist",
      cover: March25,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-mars-25/pl.u-zPyL1pYuZmv2VqP',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/2JeHR6Zs3Bf5VdhktAIbDC?si=fb5a289bf8d840ec',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://dzr.page.link/AKHoB5fW6MtChLDbA',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Février 25'",
      nameParams: "febuary-25",
      date: new Date("2025-03-01"),
      type:"playlist",
      cover: Febuary,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-fevrier-25/pl.u-yZyVP2VFYMJmL1a',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/2wnQX9xRFjpJn163kNEBas?si=e8956b0f52d34136',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://dzr.page.link/R3om9ijXFcLyHrNP6',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Janvier 25'",
      nameParams: "january-25",
      date: new Date("2025-02-05"),
      type:"playlist",
      cover: January,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-janvier-25/pl.u-XkD0YzMfDpxLMya',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/2wnQX9xRFjpJn163kNEBas?si=e8956b0f52d34136',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/69KmaQk7LrsWk4NDA',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte December 24'",
      nameParams: "december-24",
      date: new Date("2025-01-02"),
      type:"playlist",
      cover: December,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-decembre-24/pl.u-4Jomme3Cagp1qPN',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/2PujQjdiCLcV3agOE4Pq1P?si=66164338556c4006',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/eYvGcP7T8wvQJntP9',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Novembre 24'",
      nameParams: "novembre-24",
      date: new Date("2024-12-01"),
      type:"playlist",
      cover: November,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-novembre-24/pl.u-yZyVVYXFYMJmL1a',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/2YmlL8lZLj5XZbJl6oDuvE?si=0cfd1959fae049e6',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/tfMq2K2VTULXPTF67',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Octobre 24'",
      nameParams: "octobre-24",
      date: new Date("2024-11-01"),
      type:"playlist",
      cover: October,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-octobre-24/pl.u-XkD00bECDpxLMya',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/6G9ntTPTIPk4JofJATUCMf?si=2709f2d25bf140b7',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/qfHNME7qaDXm7B9T8',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Septembre 24'",
      nameParams: "september-24",
      date: new Date("2024-10-01"),
      type:"playlist",
      cover: September,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-septembre-24/pl.u-06oxxYyIWvN5kZA',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/3cWnOynYDitFbRmEAWKLyh?si=07abf12012154722',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/53QyjFkBfxnDEkQj7',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Aout 24'",
      nameParams: "aout-24",
      date: new Date("2024-09-01"),
      type:"playlist",
      cover: August,
      data: [
        {
          link: 'https://music.apple.com/us/playlist/decouverte-aout-24/pl.u-EdAVVl4uaZyek6B',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/3aoGviAMUTBL2emvLm4yxl?si=3723e3a1e72546ee',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/fNnF1ateiyVSTRqWA',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Juillet 24'",
      nameParams: "july-24",
      date: new Date("2024-08-06"),
      type:"playlist",
      cover: July,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-juillet-24/pl.u-r2yBBYBFP6o0DZr',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/1VbMtcNK9eEwUgnzxWLJBP?si=8339423b2ac14ff1',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/4UNNc9uCDxEBL2fJA',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Juin 24'",
      nameParams: "june-24",
      date: new Date("2024-07-01"),
      type:"playlist",
      cover: June,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-juin-24/pl.u-pMyllgvF49vkANE',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/0TWj7rj5crMgywKp06r5Tx?si=05ed14afcbad46b7',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/Fq4m2KESF3X47Khg7',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Mai 24'",
      nameParams: "may-24",
      date: new Date("2024-06-01"),
      type:"playlist",
      cover: May,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-mai-24/pl.u-r2yBBDXtP6o0DZr',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/6ZxjKLj7ZjuGtGzJSlGRl4?si=fc587f72305b4096',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/DKR7jhDSqFzacFWk8',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Avril 24'",
      nameParams: "april-24",
      date: new Date("2024-05-01"),
      type:"playlist",
      cover: April,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-avril-24/pl.u-4Jomm9DIagp1qPN',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/6ZxjKLj7ZjuGtGzJSlGRl4?si=6ae1a5811c3145de',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/SCw1BYWpmnNZV9CN7',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    },
    {
      name: "Decouverte Mars 24'",
      nameParams: "march-24",
      date: new Date("2024-04-01"),
      type:"playlist",
      cover: March,
      data: [
        {
          link: 'https://music.apple.com/fr/playlist/decouverte-mars-24/pl.u-zPyLLRXFZmv2VqP',
          plateform: "apple",
          name: "Apple Music"
        },
        {
          link: 'https://open.spotify.com/playlist/5F3DahB4unUJrEABtKsPWO?si=47baedb789dd40a1',
          plateform: "spotify",
          name: "Spotify"
        },
        {
          link: 'https://deezer.page.link/Cv1X3Ye7SYnqoTns9',
          plateform: "deezer",
          name: "Deezer"
        },
      ],
    }
  ]
}

export default playlistData 